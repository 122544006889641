<template>
    <div id="commande-list">
        <b-overlay :show="show" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" :agGrid="agGrid"
                    pageTitle="Liste des factures" @refresh="loadData" @filterClicked="filterClicked" />

            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue"
import AgGridDateFilter from '@/views/customer/docs/AgGridDateFilterCurrYear'
import { formatPrice } from "@/utils/formatting";

export default {
    components: {
        AgGrid
    },
    data() {
        return {
            AgGridDateFilter: AgGridDateFilter,
            startDate: new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [{
                    headerName: "N° Facture",
                    field: "No",
                    width: 200
                },
                {
                    headerName: "Date",
                    field: "Posting_Date",
                    valueGetter: function (tr) {
                        if (tr.data.Posting_Date != null)
                            return tr.data.Posting_Date.substring(0, 10);
                        return ''
                    },
                    //cellClass: 'right-customeCellRender',
                    //headerClass: 'right-customeHeaderRender',
                    width: 200,
                },
                {
                    headerName: "Montant HT",
                    field: "Amount",
                    valueGetter: function (tr) {
                        return formatPrice(tr.data.Amount);
                    },
                    //cellClass: 'right-customeCellRender',
                    //headerClass: 'right-customeHeaderRender',
                    width: 200
                },
                {
                    headerName: "Montant TVA",
                    valueGetter: function (tr) {
                        return formatPrice(tr.data.Amount_Including_VAT - tr.data.Amount);
                    },
                    //cellClass: 'right-customeCellRender',
                    //headerClass: 'right-customeHeaderRender',
                    width: 200
                },
                {
                    headerName: "Montant TTC",
                    field: "Amount_Including_VAT",
                    valueGetter: function (tr) {
                        return formatPrice(tr.data.Amount_Including_VAT);
                    },
                    //cellClass: 'right-customeCellRender',
                    //headerClass: 'right-customeHeaderRender',
                    width: 200
                },
                {
                    headerName: "Type",
                    field: "Gen_Prod_Posting_Group",
                    cellRendererFramework: 'badgeCellRenderer',
                    cellRendererParams: { componentName: "Shipments", columnName: "Gen_Prod_Posting_Group" },
                },
                {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    headerName: '',
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'buttonCellRenderer',
                    cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                    width: 100
                },

                ],
                rows: []
            },
            commandesSelected: [],
            show: true,
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.loadData();
        },
        async printDoc(event) {
            this.show = true;
            let response = "";
            if (event.No.startsWith('FTV')) {
                await this.$http.put("invoice_header", { Print: true }, { params: { No: "'" + event.No + "'", "Document_Type": "'Invoice'" } }).catch(() => this.show = false);
                response = await this.$http.get("file/document/" + event.Posting_No.replace(/:/g, "") + ".pdf?type=invoice", { responseType: "blob" }).catch(() => this.show = false);
            } else {
                await this.$http.put("validated_invoice_header", { Print: true }, { params: { No: "'" + event.No + "'" } }).catch(() => this.show = false);
                response = await this.$http.get("file/document/" + event.No.replace(/:/g, "") + ".pdf?type=invoice", { responseType: "blob" }).catch(() => this.show = false);
            }
            this.show = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            // Clean up the temporary URL
            URL.revokeObjectURL(fileURL);

        },
        async loadData() {
            this.show = true;
            let invoices = await this.$http.post("invoice_header/findWhere", this.generateDateFilters());
            let validatedInvoices = await this.$http.post("validated_invoice_header/findWhere", this.generateDateFilters());
            // Combine data from invoices and validated invoices
            this.agGrid.rows = [...invoices.data, ...validatedInvoices.data];

            // Filter out rows where 'No' starts with 'RV'
            this.agGrid.rows = this.agGrid.rows.filter(x => !x.No.startsWith('RV'));

            // Sort the rows by date in descending order
            this.agGrid.rows.sort((a, b) => new Date(b.Posting_Date) - new Date(a.Posting_Date));

            this.show = false;
        },

        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            return filters;
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>